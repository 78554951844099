import React, { useEffect } from "react"
import { pageStore } from "state/store-zustand";

import { PostProvider } from "../components/post/postContext"

import axios from "axios"

// TODO: make this a reusable hook
// TODO: replace with zustand context
import useMobileDetect from 'use-mobile-detect-hook'
const detectMobile = useMobileDetect()
const isMobile = detectMobile.isMobile()

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { getVibeGradient } from 'vibemap-constants/dist/vibes.js'

import { maskEmail } from 'react-email-mask'
import 'react-email-mask/dist/index.css'

const Leaderboard = ({ ...props }) => {

    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState([])
    const handleBadgeChange = (event) => {
        setBadge(event.target.value)
    }

    const badge = pageStore((state) => state.badge)
    const setBadge = pageStore((state) => state.setBadge)


    const badges = [
        {
            value: 'orw-2025-2',
            label: 'ORW 2025'
        },
        {
            value: 'evanston-letseat',
            label: 'Evanston Let\'s Eat'
        },
        {
            value: 'gaslamp-cocktails',
            label: 'Gaslamp Cocktails'
        },
        {
            value: 'soma-west-summer-quest',
            label: 'SOMA West Summer Quest'
        },
        {
            value: 'la-jolla-village-fall-vibes',
            label: 'La Jolla Village Fall Vibes'
        },
        {
            value: 'oakland-ballers',
            label: 'Oakland Ballers'
        },
        {
            value: 'oakland-summer-vibes-badge',
            label: 'Oakland Summer Vibes Badge'
        }
    ]

    const fetchReport = async (badge = null) => {
        // cache bust each hour
        const now = new Date()
        const hour = now.getHours()
        const minute = now.getMinutes()
        const second = now.getSeconds()
        const cache_bust = `${hour}`

        const slug = badge ? badge : 'soma-west-summer-quest'
        const endpoint = `https://api.vibemap.com/v0.7/profile/challenge-leaderboard?challenge_slug=${slug}`
        const response = await axios.get(endpoint).catch(error => console.error(error))
        const data = response.data

        console.log('data ', data)
        return data?.rows
            ? data.rows
            : data
    }


    const fetchReportOld = async (badge = null) => {
        // cache bust each hour
        const now = new Date()
        const hour = now.getHours()
        const minute = now.getMinutes()
        const second = now.getSeconds()
        const cache_bust = `${hour}`

        const endpoint = `https://api.vibemap.com/v0.3/users_challenge?fresh=${cache_bust}`
        const response = await axios.get(endpoint).catch(error => console.error(error))
        const data = response.data

        console.log('data ', data)
        return data?.data
            ? data.data
            : data
    }

    // TODO: fetch data from API
    useEffect(() => {
        // fetch data from API

        (async () => {
            // TODO: pass badge/challenge id to API
            const data = await fetchReport(badge)
            setData(data)
            setLoading(false)
        })()

    }, [badge])

    const listStyle = {
        display: 'flex',
    }

    const rowStyle = {
        padding: '4px'
    }

    const challengePointsStyle = {
        fontSize: '2rem',
        padding: '1rem',
        paddingRight: '1rem',
    }

    const titleImpact = `Leaderboard: Downtown Chicago Challenge`
    const impactAreaData = {
        impactArea: {
            backgroundColor: 'grey',
            backgroundType: 'vibe_static',
            //city: selectedCity ? selectedCity : null,
            heading: titleImpact,
            hideImpactArea: null,
            vibeset: `dreamy`
        }
    }


    const UserList = (
        <TableContainer component={Paper}>
            <Table
            aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={rowStyle}></TableCell>
                        <TableCell style={rowStyle}></TableCell>
                        <TableCell style={rowStyle}>User</TableCell>
                        <TableCell style={rowStyle} align="right">
                            <span style={{ paddingRight: '1rem'}}>
                                Challenge Points
                            </span>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data?.map((user, index) => {
                        const username = user?.user ? user?.user : user?.email
                        const username_masked = maskEmail(username)
                        console.log('DEBUG ', user, username);

                        const name = user?.name && user?.name !== ' '
                            ? user?.name : username
                        const challenge_points = user?.total_points
                        const user_vibes = user?.user_vibes
                        const vibe_points = user?.vibe_points

                        const rank = index + 1

                        const top_vibe = user_vibes && user_vibes.length > 0 ? user_vibes[0] : 'chill'
                        const colors = getVibeGradient(top_vibe)
                        const color1 = colors['color1']
                        const color2 = colors['color2']
                        // Make into linear gradient
                        const gradient = `linear-gradient(45deg, ${color1} 0%, ${color2} 100%)`

                        const avatar_initial = name.charAt(0).toUpperCase()

                        return (
                            <TableRow
                                key={username}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell scope="row" sx={rowStyle}>
                                    <span>{rank}</span>
                                </TableCell>
                                <TableCell scope="row" sx={rowStyle}>
                                    <ListItemAvatar sx={{ padding: '1rem' }}>
                                        <Avatar sx={{ background: gradient }}>
                                            {avatar_initial}
                                        </Avatar>
                                    </ListItemAvatar>
                                </TableCell>
                                <TableCell sx={rowStyle}>
                                    <span>{name}</span>
                                    <br />
                                </TableCell>
                                <TableCell
                                    align="right"
                                    padding='normal'
                                    size='large'
                                    sx={challengePointsStyle}>
                                    <span style={challengePointsStyle}>
                                        {challenge_points?.toLocaleString()}
                                    </span>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const headerStyle = {
        textAlign: 'center',
        paddingTop: '2rem'
    }

    const columnStyle = {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
        padding: isMobile ? '0' : '1rem',
        height: isMobile ? "80vh" : "50vh",
        width: isMobile ? "100%" : "60vw",
    }

    const pageStyle = {
        //background: 'linear-gradient(135deg, #ff7e5f, #feb47b)', // Cool gradient background
        transform: isMobile ? 'scale(0.8)' : 'scale(1.0)',
        width: isMobile ? '126%' : '100%',
        '-webkit-transform-origin': '0 0'
    }

    return (
        <>
            <PostProvider>
                {/* <ImpactArea data={impactAreaData} /> */}
                <main style={pageStyle}>
                    <div className='container' style={columnStyle}>
                        <FormControl fullWidth>
                            <Select
                                value={badge}
                                onChange={handleBadgeChange}
                                options={badges}
                                style={{ textAlign: 'left' }} >
                                {badges.map((badge) => {
                                    return (
                                        <MenuItem ion value={badge.value}>
                                            {badge.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        {loading
                            ? <div>Loading...</div>
                            : UserList
                        }
                    </div>
                </main>
            </PostProvider>
        </>
    )
}

export default Leaderboard
